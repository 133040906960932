<template>
  <div class="sale-wrapper bj">
    <div class="title">
      <div class="seach">
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="bjsearch"
            @click="Refresh"
          ></el-button>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.promotion_tag_id"
            clearable
            filterable
            placeholder="请选择标签"
          >
            <el-option
              v-for="item of searchdata.promotion_tag_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.origin_name"
            placeholder="输入产地"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.grade"
            clearable
            filterable
            placeholder="请选择等级"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.product_name"
            placeholder="输入商品名称"
            clearable
          ></el-input>
        </div>

        <div class="inputs">
          <el-input
            v-model="from1.sku_code"
            placeholder="输入货号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.supplier_name"
            placeholder="输入供应商名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.buyer_name"
            placeholder="输入采购员名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="video_time"
            start-placeholder="视频最后一次更新时间起"
            end-placeholder="视频最后一次更新时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'video_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="kv_time"
            start-placeholder="主图最后一次更新时间起"
            end-placeholder="主图最后一次更新时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'kv_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of searchdata.business_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.first_cid"
            clearable
            filterable
            placeholder="请选择一级品类"
          >
            <el-option
              v-for="item of searchdata.first_category_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.cid"
            clearable
            filterable
            placeholder="请选择二级品类"
          >
            <el-option
              v-for="item of searchdata.second_category_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.category_tag_id"
            clearable
            filterable
            placeholder="请选择三级品类"
          >
            <el-option
              v-for="item of searchdata.category_tag_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="delivery_time"
            start-placeholder="创建日期始"
            end-placeholder="创建日期止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => timechange(val, 'delivery_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs" style="width: 250px !important">
          <el-input
            v-model="from1.sku_list"
            placeholder="货号批量搜索,中间英文逗号隔开"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="online_time"
            start-placeholder="最后上架时间起"
            end-placeholder="最后上架时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'online_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="offline_time"
            start-placeholder="最后下架时间起"
            end-placeholder="最后下架时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'offline_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select v-model="from1.state" clearable placeholder="请选择状态">
            <el-option
              v-for="item of stateOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-button type="primary" icon="el-icon-search" @click="search"
            >查询</el-button
          >
          <el-button
            @click="onHandleExport"
            type="warning"
            icon="el-icon-upload2"
            :disabled="disabledExport"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <!-- <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column
          prop="logistic_business_name"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.logistic_business_name) }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="fullname"
          align="center"
          label="商品主图"
          fixed="left"
          min-width="100px"
        >
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.kv" alt="" class="goods-image" />
            </div>
            <div>{{ scope.row.kv_last_time_str || "" }}</div>
            <div>{{ scope.row.srp_video_last_time_str || "" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="fullname"
          align="center"
          label="商品名称"
          fixed="left"
          min-width="100px"
        >
          <template slot-scope="scope">
            {{
              `${$empty.empty(scope.row.title)}(${$empty.empty(
                scope.row.sku_code
              )})`
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="name" align="center" label="货号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.sku_code) }}
          </template>
        </el-table-column> -->
        <el-table-column prop="name" align="center" label="等级">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.grade) }}级
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="毛重">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.gross_weight) }}斤
          </template>
        </el-table-column>
        <el-table-column
          prop="gross_weight_price"
          align="center"
          label="毛单价"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.gross_weight_price) }}元/斤
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="净重">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.weight) }}斤
          </template>
        </el-table-column>
        <el-table-column prop="weight_price" align="center" label="净单价">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.weight_price) }}元/斤
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="开市价">
          <template slot-scope="scope">
            {{ tool.toDecimal2((scope.row.price * 10000) / 100 / 10000) }}元
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="供货商">
          <template slot-scope="scope">
            <span v-if="scope.row.supplier">{{
              $empty.empty(scope.row.supplier.title)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="采购员">
          <template slot-scope="scope">
            <span v-if="scope.row.buyer">{{
              $empty.empty(scope.row.buyer.fullname)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="创建时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="库存数量">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.stock) }}
          </template>
        </el-table-column>
        <el-table-column prop="first_cid_name" align="center" label="一级品类">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.first_cid_name) }}
          </template>
        </el-table-column>
        <el-table-column prop="cid_name" align="center" label="二级品类">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.cid_name) }}
          </template>
        </el-table-column>
        <el-table-column prop="category_tag" align="center" label="三级品类">
          <template slot-scope="scope">
            {{ (scope.row.category_tag && scope.row.category_tag.name) || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="origin_name" align="center" label="产地">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.origin_name) }}
          </template>
        </el-table-column>
        <el-table-column prop="spec" align="center" label="规格">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.spec) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="last_online_time"
          align="center"
          label="最后上架时间"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.last_online_time) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="last_offline_time"
          align="center"
          label="最后下架时间"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.last_offline_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="promotion_tag" align="center" label="标签">
          <template slot-scope="scope">
            {{ scope.row.promotion_tag ? scope.row.promotion_tag : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="intro_20" align="center" label="简介">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.intro_20) }}
          </template>
        </el-table-column>
        <el-table-column prop="total_score" align="center" label="得分">
        </el-table-column>
        <el-table-column prop="state_txt" align="center" label="状态">
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          align="center"
          min-width="130"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="storageHandle(scope.row)"
              >查看发售仓</el-button
            >
            <el-button type="text" @click="onHandleQrCode(scope.row)"
              >小程序二维码</el-button
            >
            <el-button type="text" @click="editData(scope.row)">编辑</el-button>
            <el-button type="text" @click="onHandleDetails(scope.row)"
              >查看变更记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 发售仓 -->
    <div class="enterprise personindex">
      <el-dialog
        :center="true"
        @close="tableVisible = false"
        :visible="tableVisible"
        :title="'查看发售仓(' + listtotal + ')'"
        width="70%"
      >
        <div class="row-box d-flex flex-column" style="height: 100%">
          <el-table
            :data="listData"
            height="100%"
            v-loading="loading"
            :border="true"
            :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
          >
            <el-table-column
              type="index"
              width="100px"
              align="center"
              label="序号"
            >
            </el-table-column>
            <el-table-column prop="name" align="center" label="城市仓">
              <template slot-scope="scope">
                {{ $empty.empty(scope.row.name) }}
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="pagination-position">
            <el-pagination
              type="primary"
              background
              @size-change="handleListChange"
              @current-change="handleListCurrentChange"
              :current-page="currentlistPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="salePageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="listtotal"
            >
            </el-pagination>
          </div>
        </div>
        <template #footer>
          <el-button @click="tableVisible = false">返回</el-button>
        </template>
      </el-dialog>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 当前列数据生成的二维码 start -->
    <qr-code-dialog ref="refQrCode"></qr-code-dialog>
    <!-- 当前列数据生成的二维码 end -->
    <!-- 商品变更记录，弹窗 start -->
    <goods-change-dialog ref="refGoodsChangeDialog"></goods-change-dialog>
    <!-- 商品变更记录，弹窗 end -->
    <!-- 商品编辑start -->
    <Salegoodseditdialog
      ref="Salegoodseditdialog"
      type="2"
      @tablechange="tablechange"
    ></Salegoodseditdialog>
    <!-- 商品编辑end -->
    <div v-if="detailsVisible" class="dialogbj">
      <div style="text-align: right">
        <i class="el-icon-circle-close" @click="detailsVisible = false"></i>
      </div>

      <goods-details
        :loading="detailsLoading"
        :details="goodsDetail"
      ></goods-details>
    </div>
  </div>
</template>
<script>
import { BASE } from "@/api";
import url from "url";
import QrCodeDialog from "@/components/common/QrCodeDialog/index.vue";
import { MEMBER_QR_CODE_CONFIG, MINI_APP_PR_CODE_PAGE } from "@/utils/enum";
import { getMiniAppQrCode } from "@/api/export";
import { postProductListExport } from "@/api/export/center.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
import GoodsChangeDialog from "./modules/goods-change-dialog/index.vue";
import Salegoodseditdialog from "./modules/Salegoods-edit-dialog/index.vue";
import GoodsDetails from "./modules/goods-change-dialog/modules/goods-details.vue";

export default {
  name: "supplier",
  components: {
    QrCodeDialog,
    GoodsChangeDialog,
    GoodsDetails,
    Salegoodseditdialog,
  },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      detailsVisible: false,
      delivery_time: [],
      video_time: [], // 视频最后更新时间
      online_time: [], // 最后上架时间
      offline_time: [], // 最后下架时间
      kv_time: [], //主图最后更新时间
      from: {
        is_tdc_owner: "", // 仓主专区
        logistic_business_id: "", //集配中心id
        promotion_tag_id: "", //促销标签id
        category_tag_id: "", // 品种/品牌id
        grade: "", //等级 A、B、C
        product_name: "", //商品名称
        sku_code: "", //商品编码
        supplier_name: "", // 供应商名称
        buyer_name: "", //采购名称
        sku_list: "", // 批量货号搜索
        supplier_id: "", //供应商id
        buyer_id: "", //采购id
        start_time: "", //开始时间
        end_time: "", //结束时间
        first_cid: "", //一级分类id
        cid: "", //二级分类id
        state: "1,3", // 全部状态
        page: 1,
        pageSize: 100,
        origin_name: "", // 产地
        online_start_time: "", // 最后一次上架时间起
        online_end_time: "", // 最后一次上架时间止
        video_start_time: "", // 视频最后更新时间起
        video_end_time: "", // 视频最后更新时间止
        kv_start_time: "", // 主图最后更新时间起
        kv_end_time: "", // 主图最后更新时间止
        offline_start_time: "", //最后一次下架时间起
        offline_end_time: "", //最后一次下架时间止
      },
      from1: {
        is_tdc_owner: "", // 仓主专区
        logistic_business_id: "", //集配中心id
        promotion_tag_id: "", //促销标签id
        category_tag_id: "", // 品种/品牌id
        grade: "", //等级 A、B、C
        product_name: "", //商品名称
        sku_code: "", //商品编码
        supplier_name: "", // 供应商名称
        buyer_name: "", //采购名称
        sku_list: "", // 批量货号搜索
        supplier_id: "", //供应商id
        buyer_id: "", //采购id
        start_time: "", //开始时间
        end_time: "", //结束时间
        first_cid: "", //一级分类id
        cid: "", //二级分类id
        state: "1,3", // 全部状态
        page: 1,
        pageSize: 100,
        origin_name: "", // 产地
        online_start_time: "", // 最后一次上架时间起
        online_end_time: "", // 最后一次上架时间止
        video_start_time: "", // 视频最后更新时间起
        video_end_time: "", // 视频最后更新时间止
        kv_start_time: "", // 主图最后更新时间起
        kv_end_time: "", // 主图最后更新时间止
        offline_start_time: "", //最后一次下架时间起
        offline_end_time: "", //最后一次下架时间止
      },
      salePageSize: 20,
      saleid: "",
      tableData: [],
      delivery_time: [],
      listData: [],
      tableVisible: false,
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      ruleForm: {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        itemdata: "",
        name: "",
        username: "",
        password: "",
      },
      title: "",
      centerDialogVisible: false,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        business_type_code: [
          { required: true, message: "请选择业务模块", trigger: "change" },
        ],
        itemdata: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
      },
      options1: [],
      stateOptions: [
        {
          name: "全部状态",
          value: "1,3",
        },
        {
          name: "待审核",
          value: "1",
        },
        {
          name: "已下架",
          value: "3",
        },
      ], // 状态下拉
      options: [
        {
          id: "A",
        },
        {
          id: "B",
        },
        {
          id: "C",
        },
      ], // 等级
      list: [],
      list1: [],
      currentlistPage: 1,
      listtotal: 0,
      searchdata: "",
      disabledExport: false, // 导出按钮
    };
  },
  created() {
    this.hqlist();
    // this.getBusinessList();
    this.getsearchdata();
  },
  watch: {
    "ruleForm.business_type_code": {
      handler(newVal, oldVal) {
        if (newVal == "logistics") {
          this.options1 = this.list;
        } else {
          this.options1 = this.list1;
        }
        this.ruleForm.store_id = "";
        this.ruleForm.name = "";
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /**
     * 导出逻辑
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.from1,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postProductListExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postPurchaseSettleExport err", err);
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    // 视频最后一次更新时间 主图最后一次更新时间 最后上架时间修改
    addTimeChange(val, name) {
      console.log(val, name, "name===");
      if (name == "online_time") {
        if (val) {
          this.from.online_start_time = val[0];
          this.from.online_end_time = val[1];
          this.from1.online_start_time = val[0];
          this.from1.online_end_time = val[1];
        } else {
          this.from.online_start_time = "";
          this.from.online_end_time = "";
          this.from1.online_start_time = "";
          this.from1.online_end_time = "";
        }
      } else if (name == "video_time") {
        if (val) {
          this.from.video_start_time = val[0];
          this.from.video_end_time = val[1];
          this.from1.video_start_time = val[0];
          this.from1.video_end_time = val[1];
        } else {
          this.from.video_start_time = "";
          this.from.video_end_time = "";
          this.from1.video_start_time = "";
          this.from1.video_end_time = "";
        }
      } else if (name == "kv_time") {
        if (val) {
          this.from.kv_start_time = val[0];
          this.from.kv_end_time = val[1];
          this.from1.kv_start_time = val[0];
          this.from1.kv_end_time = val[1];
        } else {
          this.from.kv_start_time = "";
          this.from.kv_end_time = "";
          this.from1.kv_start_time = "";
          this.from1.kv_end_time = "";
        }
      } else if (name == "offline_time") {
        if (val) {
          this.from.offline_start_time = val[0];
          this.from.offline_end_time = val[1];
          this.from1.offline_start_time = val[0];
          this.from1.offline_end_time = val[1];
        } else {
          this.from.offline_start_time = "";
          this.from.offline_end_time = "";
          this.from1.offline_start_time = "";
          this.from1.offline_end_time = "";
        }
      }
    },
    // 创建时间修改
    timechange(val, name) {
      if (val) {
        this.from.start_time = val[0];
        this.from.end_time = val[1];
        this.from1.start_time = val[0];
        this.from1.end_time = val[1];
      } else {
        this.from.start_time = "";
        this.from.end_time = "";
        this.from1.start_time = "";
        this.from1.end_time = "";
      }
    },
    // 获取搜索字段列表合集
    async getsearchdata() {
      try {
        const res = await this.$api.general.searchdata();
        console.log(res);
        this.searchdata = res.data;
      } catch (error) {
        console.log(error, "searchdata");
      }
    },
    //编辑
    editData(e) {
      console.log(e, "11111111111111");

      this.$refs.Salegoodseditdialog.onInitData(e);
    },
    /**
     * 查看变更记录
     */
    onHandleDetails(row) {
      const params = {
        skuCode: row.sku_code,
      };
      this.$refs.refGoodsChangeDialog.onInitData(params);
    },
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    search() {
      console.log(this.from1.sku_code, "sku===");
      if (this.from1.sku_code && this.from1.sku_code.length < 4) {
        this.tool.message("货号输入至少4位", "error");
        return;
      }
      this.from.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.hqlist();
    },
    tablechange() {
      this.hqlist();
    },
    saleLogisticsList(id) {
      this.$api.general
        .saleLogisticsList({
          product_id: id,
          page: this.currentlistPage,
          pageSize: this.salePageSize,
        })
        .then((res) => {
          this.listData = res.data.data;
          this.listtotal = res.data.total;
        });
    },
    storageHandle(data) {
      this.currentlistPage = 1;
      this.saleid = data.id;
      this.saleLogisticsList(data.id);
      this.tableVisible = true;
    },
    handleListChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.salePageSize = val;
      this.currentlistPage = 1;
      this.saleLogisticsList(this.saleid);
    },
    handleListCurrentChange(val) {
      this.currentlistPage = val;
      this.saleLogisticsList(this.saleid);
    },
    Refresh() {
      this.from = {
        is_tdc_owner: "", // 仓主专区
        logistic_business_id: "", //集配中心id
        promotion_tag_id: "", //促销标签id
        category_tag_id: "", // 品种/品牌id
        grade: "", //等级 A、B、C
        product_name: "", //商品名称
        sku_code: "", //商品编码
        supplier_name: "", // 供应商名称
        buyer_name: "", //采购名称
        sku_list: "", // 批量货号搜索
        supplier_id: "", //供应商id
        buyer_id: "", //采购id
        start_time: "", //开始时间
        end_time: "", //结束时间
        first_cid: "", //一级分类id
        cid: "", //二级分类id
        // hot_sales: "",
        state: "1,3", // 上架
        page: 1,
        pageSize: 100,
        origin_name: "", // 产地
        online_start_time: "", // 最后一次上架时间起
        online_end_time: "", // 最后一次上架时间止
        video_start_time: "", // 视频最后更新时间起
        video_end_time: "", // 视频最后更新时间止
        kv_start_time: "", // 主图最后更新时间起
        kv_end_time: "", // 主图最后更新时间止
        offline_start_time: "", //最后一次下架时间起
        offline_end_time: "", //最后一次下架时间止
      };
      this.from1 = {
        is_tdc_owner: "", // 仓主专区
        logistic_business_id: "", //集配中心id
        promotion_tag_id: "", //促销标签id
        category_tag_id: "", // 品种/品牌id
        grade: "", //等级 A、B、C
        product_name: "", //商品名称
        sku_code: "", //商品编码
        supplier_name: "", // 供应商名称
        buyer_name: "", //采购名称
        sku_list: "", // 批量货号搜索
        supplier_id: "", //供应商id
        buyer_id: "", //采购id
        start_time: "", //开始时间
        end_time: "", //结束时间
        first_cid: "", //一级分类id
        cid: "", //二级分类id
        // hot_sales: "",
        state: "1,3", // 上架
        page: 1,
        pageSize: 100,
        origin_name: "", // 产地
        online_start_time: "", // 最后一次上架时间起
        online_end_time: "", // 最后一次上架时间止
        video_start_time: "", // 视频最后更新时间起
        video_end_time: "", // 视频最后更新时间止
        kv_start_time: "", // 主图最后更新时间起
        kv_end_time: "", // 主图最后更新时间止
        offline_start_time: "", //最后一次下架时间起
        offline_end_time: "", //最后一次下架时间止
      };
      this.delivery_time = [];
      this.kv_time = [];
      this.video_time = [];
      this.online_time = [];
      this.offline_time = [];
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.general.productList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    /**
     * 展示二维码
     */
    async onHandleQrCode(row) {
      const { sku_code, title: name } = row;
      const { sku } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      const params = {
        env_version: sku.envCode,
        type: MINI_APP_PR_CODE_PAGE.sku.value,
        sku_code,
      };
      try {
        const res = await getMiniAppQrCode(params);
        downLoadFlowBlob(res, res?.filename || name);
      } catch (err) {
        console.log("ajax getMiniAppQrCode err", err);
      }
      /** 前端生成二维码逻辑，已弃 */
      // const { id, sku_code, title: name, price } = row;
      // const { sku } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      // const url =
      //   sku?.value &&
      //   `${sku.value}?id=${id}&price=${price}&sku_code=${sku_code}`;
      // const url = sku?.value && `${sku.value}?sku_code=${sku_code}`;
      // const params = {
      //   url,
      //   name,
      // };
      // this.$refs.refQrCode.onInitData(params);
      // console.log("🆒 onHandleQrCode", row, params);
    },
  },
};
</script>
<style lang="scss">
.sale-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .goods-image {
    width: 50px;
    height: 50px;
  }

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}
.dialogbj {
  // text-align: right;
  i {
    font-size: 30px;
  }
  width: 375px;
  height: 820px;
  position: fixed;
  background-color: #fff;
  top: 50%;
  left: 50%;
  // background-color: #000;

  transform: translateX(-50%) translateY(-50%);
}
// .el-picker-panel {
//   left: 1245px !important;
// }
</style>
